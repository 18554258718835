import { Button } from 'bootstrap';
import React, { Component } from 'react';

export class Home extends Component {
    static displayName = Home.name;
    constructor(props) {
        super(props);
        this.inputChanged = this.inputChanged.bind(this);
        this.inputHatChanged = this.inputHatChanged.bind(this);
        this.refocusHat = this.refocusHat.bind(this);
        this.getStatus = this.getStatus.bind(this);
        this.checkForHatEscape = this.checkForHatEscape.bind(this);
        this.checkForOrderEnter = this.checkForOrderEnter.bind(this);
        this.clearData = this.clearData.bind(this);
        this.enterInitials = this.enterInitials.bind(this);
        this.clearInitials = this.clearInitials.bind(this);
        this.lookupOrder = this.lookupOrder.bind(this);
        this.initialsChanged = this.initialsChanged.bind(this);
        this.returnFromSku = this.returnFromSku.bind(this);
        this.mapSku = this.mapSku.bind(this);
        this.enterOnInitials = this.enterOnInitials.bind(this);
        this.updateUpcOnSku = this.updateUpcOnSku.bind(this);
        this.state = {
            selectedOrder: null,
            currentOrderLookup: '',
            currentHatLookup: '',
            initials: '',
            currentInitials: null,
            updateSku: null

        };
    }
    getStatus(oi) {
        if (oi.shipOrderItem.isShipped) {
            return "#ccffcc";
        }
        else {
            return "#cc3333";
        }
    }
    initialsChanged(e) {
        var val = e.target.value;
        this.setState({ initials: val });
    }
    enterInitials() {
        this.setState({ selectedOrder: null, currentHatLookup: '', currentOrderLookup: '', currentInitials: this.state.initials });
        setTimeout(() => {
            this.refocusHat();
        }, 0);
    }
    clearInitials() {
        this.setState({ currentInitials: null });
    }
    inputChanged(e) {
        var val = e.target.value;
        this.lookupOrder(val);
    };
    mapSku(d) {
        //debugger;
        this.setState({ updateSku: d });
    }
    returnFromSku() {
        var d = {
            sku: this.state.updateSku.shipOrderItem.sku,
            upc: this.state.updateSku.shipOrderItem.upc,
            mappedUpc: this.state.updateSku.mappedUpc
        }


        fetch('/Order/MapUpcToSku', {
            method: "POST",
            headers: new Headers({
                'accept': 'application/json',
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(d)
        })
            .then(res => res.json())
            .then(
                (result: any) => {
                    debugger;
                    this.setState({ updateSku: null });
                    
                    setTimeout(() => {
                            this.refocusHat();
                    }, 0);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {


                }
            )
        
    }
    lookupOrder(val) {
        this.setState({ currentOrderLookup: val });
        //var result = data.match(new RegExp('^#^(.?*)'));
        var rex = /^(\^#\^)(.+)(\^)$/i;
        if (val.match(rex)) {
            var r = val.replace(rex, '$2');
            //console.log(data + '  ' + result)
            //console.log('0x' + result)
            console.log(parseInt('0x' + r, 16));
            var d = JSON.stringify({ externalId: parseInt('0x' + r, 16) + '' });

            fetch('/Order/GetOrder', {
                method: "POST",
                headers: new Headers({
                    'accept': 'application/json',
                    'Content-Type': 'application/json'
                }),
                body: d
            })
                .then(res => res.json())
                .then(
                    (result: any) => {
                        //debugger;
                        this.setState({ selectedOrder: result });
                        setTimeout(() => {
                            if (this.hatInput != null) {
                                this.hatInput.focus();
                            }
                        }, 0);
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {


                    }
                )

        }
        else {
            console.log('nope');
        }
    }
    refocusHat() {
        setTimeout(() => {
            if (this.state.selectedOrder == null) {
                this.orderInput.focus();
            }
            else {
                if (this.hatInput != null) {
                    this.hatInput.focus();
                }
            }
        }, 0);


    };
    updateUpcOnSku(e) {
        var val = e.target.value;
        var sku = this.state.updateSku;
        sku.mappedUpc = val;
        this.setState({ updateSku: sku });
    }
    inputHatChanged(e) {
        var val = e.target.value;
        var found = false;
        var order = this.state.selectedOrder;
        //debugger;
        for (var i = 0; i < order.orderItemInfos.length; i++) {
            var item = order.orderItemInfos[i];
            var list = order.orderItemInfos[i].mappedUpc.split(",");
            var intFound = false;
            for (var z = 0; z < list.length; z++) {
                if (list[z] == val) {
                    intFound = true;
                }
            }
            if (intFound || item.shipOrderItem.sku === val) {
                found = true;
                item.shipOrderItem.isShipped = true;
            }
        }
        if (!found) {
            this.setState({ currentHatLookup: val });
        }
        else {
            //we are good, and updating values, but before blindly setting it, lets check all of it and close out if done
            var allGood = true;
            for (i = 0; i < order.orderItemInfos.length; i++) {
                if (!order.orderItemInfos[i].shipOrderItem.isShipped) {
                    allGood = false;
                    break;
                }
            }
            if (allGood) {
                order.shipOrder.isBoxed = true;
                fetch('/Order/ShipOrder', {
                    method: "POST",
                    headers: new Headers({
                        'accept': 'application/json',
                        'Content-Type': 'application/json'
                    }),
                    body: JSON.stringify({
                        ShipOrderId: order.shipOrder.id,
                        Initials: this.state.currentInitials
                    })
                })
                    .then(res => res.json())
                    .then(
                        (result: any) => {
                            this.setState({ selectedOrder: order, currentHatLookup: '' });
                        },
                        // Note: it's important to handle errors here
                        // instead of a catch() block so that we don't swallow
                        // exceptions from actual bugs in components.
                        (error) => {


                        }
                    )
                this.setState({ selectedOrder: order, currentHatLookup: '' });
            }
            else {
                this.setState({ selectedOrder: order, currentHatLookup: '' });
            }

        }
    };
    checkForHatEscape(e) {
        if (e.keyCode === 27) {
            this.setState({ selectedOrder: null, currentHatLookup: '', currentOrderLookup: '' });
            this.refocusHat();
        }
    };
    enterOnInitials(e) {
        if (e.keyCode === 13) {
            this.enterInitials();
        }
    };
    checkForOrderEnter(e) {
        if (e.keyCode === 13) {
            if (this.state.currentOrderLookup.length > 0 && parseInt(this.state.currentOrderLookup) > 0) {
                var val = '^#^' + parseInt(this.state.currentOrderLookup).toString(16) + '^';
                this.setState({ currentOrderLookup: val });
                this.lookupOrder(val);
            }

        }
    }
    clearData(e) {
        this.setState({ selectedOrder: null, currentHatLookup: '', currentOrderLookup: '' });
        setTimeout(() => {
            this.orderInput.focus();
        }, 0);

    }
    componentDidMount() {
        if (this.orderInput) {
            this.orderInput.focus();
        }
    };

    render() {
        return (
            <div>
                {this.state.currentInitials == null ? <div style={{ marginTop: "20px", width: "300px" }}>
                    <input style={{ float: "left", width: "300px" }} type="text" onChange={this.initialsChanged} value={this.state.initials} placeholder="Please enter initials" onKeyDown={this.enterOnInitials} /> <div style={{ float: "left", width: "300px", border: "1px solid #000000", marginTop: "10px", backgroundColor: "#ccc", textAlign: "center" }} onClick={this.enterInitials}>continue</div>
                </div> :
                    (

                        this.state.updateSku != null ? <div style={{ marginTop: "20px", width: "600px" }}>

                            <div style={{ width: "600px", marginTop: "10px" }}>
                                <span style={{ float: "left", width: "600px" }}>Mapped UPCs for SKU: {this.state.updateSku.sku}</span>
                                <input style={{ float: "left", width: "600px" }} type="text" value={this.state.updateSku.mappedUpc} onChange={this.updateUpcOnSku} />{/* onChange={ this.updateSkuText} value={this.state.updateSku} */}
                            </div>
                            <div style={{ clear: "both" }}></div>
                            <div type="Button" onClick={this.returnFromSku} style={{ width: "300px", border: "1px solid #000000", backgroundColor: "#ccc", textAlign: "center", marginTop: "10px" }}>Save</div>

                        </div>
                            :
                            <div>
                                <div type="Button" onClick={this.clearInitials} style={{ width: "300px", border: "1px solid #000000", backgroundColor: "#ccc", textAlign: "center", marginTop: "10px" }}>Change Initials</div>

                                <div style={{ width: "400px", marginTop: "10px" }}>
                                    <span style={{ float: "left", width: "100px" }}>Enter order:</span>
                                    <input ref={inputEl => (this.orderInput = inputEl)} onBlur={this.refocusHat} style={{ float: "left", width: "300px" }} type="text" onChange={this.inputChanged} onKeyDown={this.checkForOrderEnter} value={this.state.currentOrderLookup} />
                                </div>
                                <div style={{ clear: "both" }}></div>
                                {this.state.selectedOrder != null && <div>
                                    <div style={{ width: "400px", marginTop: "10px" }}>
                                        <span style={{ float: "left", width: "100px" }} >Scan hats:</span>
                                        <input ref={inputEl => (this.hatInput = inputEl)} onKeyDown={this.checkForHatEscape} onBlur={this.refocusHat} style={{ float: "left", width: "300px" }} type="text" onChange={this.inputHatChanged} value={this.state.currentHatLookup} />
                                    </div>
                                    <div style={{ clear: "both" }}></div>
                                </div>
                                }

                                {this.state.updateSku === null && this.state.selectedOrder != null ? <div>
                                    <div onClick={this.clearData} style={{ width: "300px", border: "1px solid #000000", backgroundColor: "#ccc", textAlign: "center", marginTop: "10px" }}>New Order</div>
                                    <br /><br />
                                    <div>Shipstation Id: {this.state.selectedOrder.shipOrder.externalId}</div>
                                    <div>Web Order Id: {this.state.selectedOrder.order.webOrderId}</div>
                                    <div>Order Items: {
                                        this.state.selectedOrder.orderItemInfos.map((oi, index) => {
                                            return <div key={index} style={{ backgroundColor: this.getStatus(oi), width: '800px' }}>
                                                <div style={{ width: '240px', float: 'left' }}>
                                                    <img src={oi.orderItem.imageUrl} style={{ height: "240px" }}></img>
                                                </div>
                                                <div style={{ width: '560px', float: 'left' }}>
                                                    <div>SKU:{oi.orderItem.sku} Quantity: {oi.orderItem.quantity} UPC: {oi.mappedUpc} </div>
                                                    <div>{oi.orderItem.name}</div>
                                                    <div type="Button" onClick={() => { this.mapSku(oi) }} style={{ width: "300px", border: "1px solid #000000", backgroundColor: "#ccc", textAlign: "center", marginTop: "10px" }}>Mapped UPC</div>

                                                </div>
                                                <div style={{ clear: 'both' }}></div>
                                            </div>
                                        })

                                    }</div>


                                </div>
                                    :
                                    <></>
                                }

                            </div>
                    )
                }

            </div>
        );
    }
}
